<template>
  <div id="aba-dados-principais">
    <card v-if="loading">
      <small-loading :loading="true"></small-loading>
    </card>

    <template v-else>
      <card :titulo="`Imóvel - ${labelCategoria}`">
        <card-content>

          <p>
            <span class="font-weight-500">CNM: </span><span>{{ficha.cnm}}</span>
            <button class="btn btn-sm btn-outline-info pointer ml-2" @click="gerarCNM(ficha.id)">  {{ tituloCnm }}
            </button>
          </p>

          <p>
            <span class="font-weight-500">Situação: </span><span>{{status.find(s => s.id === ficha.status).nome}}</span>
          </p>

          <p v-if="ficha.cadastro">
            <span class="font-weight-500">Cadastro: </span><span>{{ficha.cadastro | datahora}}</span>
          </p>

          <p v-if="ficha.abertura">
            <span class="font-weight-500">Abertura: </span><span>{{ficha.abertura | datahora}}</span>
          </p>

          <p v-if="ficha.encerramento && (ficha.status === 'ENCERRADA' || ficha.status === 'CANCELADA')">
            <span v-if="ficha.status === 'ENCERRADA'" class="font-weight-500">Encerramento: </span><span v-if="ficha.status === 'CANCELADA'" class="font-weight-500">Cancelamento: </span><span>{{ficha.encerramento | datahora}}</span>
          </p>

          <p>
            <span class="font-weight-500">Tipo de imóvel: </span>
            <span>
              {{ labelTipoImovel }}
              <template v-if="ficha.descricaoTipoImovelOutros && ficha.tipoImovel === 'OUTROS'">
                - {{ ficha.descricaoTipoImovelOutros }}
              </template>
            </span>
          </p>

          <p v-if="ficha.imovelUniao !== null">
            <span class="font-weight-500">Imóvel da União: </span><span>{{ficha.imovelUniao ? 'Sim' : 'Não'}}</span>
          </p>

          <p v-if="ficha.marinha !== null">
            <span class="font-weight-500">Imóvel da Marinha: </span><span>{{ficha.marinha ? 'Sim' : 'Não'}}</span>
          </p>

          <p v-if="ficha.fronteira !== null">
            <span class="font-weight-500">Imóvel de Fronteira: </span><span>{{ficha.fronteira ? 'Sim' : 'Não'}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Transcrição"
            v-if="ficha.livro == 'TRANSCRICAO'">
        <card-content>

          <p v-if="ficha.livroTranscricao">
            <span class="font-weight-500">Livro: </span><span>{{livroTranscricao}}</span>
          </p>

          <p v-if="ficha.livroNumero">
            <span class="font-weight-500">Livro (Número): </span><span>{{ficha.livroNumero}}</span>
          </p>

          <p v-if="ficha.livroLetra">
            <span class="font-weight-500">Livro (Letra): </span><span>{{ficha.livroLetra}}</span>
          </p>

          <p v-if="ficha.livroFolha">
            <span class="font-weight-500">Folha: </span><span>{{ficha.livroFolha}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Registro Anterior" v-if="ficha.origens && ficha.origens.length > 0">

        <card-table class="table-borderless mb-1">

          <thead v-if="mostrarCabecalhoOrigens">
            <tr>
              <th>Serventia</th>
              <th class="text-center" width="120px">Tipo</th>
              <th class="text-center" width="90px">Registro</th>
              <th class="text-center" width="90px">Número</th>
              <th class="text-center" width="90px">Letra</th>
              <th class="text-center" width="90px">Folha</th>
              <th>Complemento</th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(origem, index) in origens">
              <tr :key="'origens-legada-'+index" v-if="origem.tipo == 'LEGADO' || origem.tipo == 'TEXTO'">
                <td colspan="7">
                  {{origem.registro}}
                  <div class="small text-muted" v-if="origem.serventia">Serventia: {{origem.serventia}}</div>
                </td>
              </tr>
              <tr :key="'origens'+index" v-else>
                <td class="pl-2">{{origem.serventia}}</td>

                <td class="pr-2 text-center">{{origem.tipo}}</td>

                <td class="text-center pr-2" :class="{'icon': origem.consulta}">
                  <a @click.prevent="detalhesIndicadorReal(origem)" :style="{ color: !origem.consulta ? 'inherit !important':''}">{{formatarRegistro(origem.registro)}}</a>
                </td>
                <td class="text-center pr-2">{{origem.livroNumero == 'null' ?  '' : origem.livroNumero}}</td>
                <td class="text-center pr-2">{{origem.livroLetra == 'null' ?  '' : origem.livroLetra}}</td>
                <td class="text-center pr-2">{{origem.livroFolha == 'null' ?  '' : origem.livroFolha}}</td>
                <td class="text-center pr-2">{{origem.complemento == 'null' ?  '' : origem.complemento}}</td>

              </tr>
            </template>

          </tbody>

        </card-table>

      </card>

      <referencia-imovel-lista :ficha="ficha" :visualizar="true" v-if="ficha.referencias && ficha.referencias.length > 0"/>

      <card titulo="Imóvel da União" v-if="ficha.imovelUniao && (ficha.numeroRipSpu || ficha.regime || ficha.medidaAreaUniao || ficha.areaUniao)">
        <card-content>
          <p v-if="ficha.numeroRipSpu">
            <span class="font-weight-500">Número RIP SPU: </span><span>{{this.$formatters.numeroRipSpu(ficha.numeroRipSpu)}}</span>
          </p>

          <p v-if="ficha.regime">
            <span class="font-weight-500">Regime: </span><span>{{regimes.find(s => s.id === ficha.regime).nome}}</span>
          </p>

          <p v-if="ficha.areaUniao">
            <span class="font-weight-500">Área Terreno da União: </span><span>{{$formatters.currency(ficha.areaUniao,{min : 2, max : 10})}} {{unidadeAreaUniao}}</span>
          </p>
        </card-content>
      </card>

      <card titulo="Identificação"
            v-if="ficha.livroLetra || ficha.unidade || ficha.bloco || ficha.quadra || ficha.lote || ficha.localizacao || ficha.empreendimento">
        <card-content>
          <p v-if="ficha.livroLetra">
            <span class="font-weight-500">Livro (Letra): </span><span>{{ficha.livroLetra}}</span>
          </p>
          <p v-if="ficha.unidade">
            <span class="font-weight-500">Número da unidade: </span><span>{{ficha.unidade}}</span>
          </p>
          <p v-if="ficha.bloco">
            <span class="font-weight-500">Bloco: </span><span>{{ficha.bloco}}</span>
          </p>
          <p v-if="ficha.quadra">
            <span class="font-weight-500">Quadra: </span><span>{{ficha.quadra}}</span>
          </p>
          <p v-if="ficha.lote">
            <span class="font-weight-500">Lote: </span><span>{{ficha.lote}}</span>
          </p>
          <p v-if="ficha.localizacao">
            <span class="font-weight-500">Localização: </span><span>{{ficha.localizacao}}</span>
          </p>
          <p v-if="ficha.empreendimento">
            <span class="font-weight-500">Empreendimento: </span><span>{{ficha.empreendimento}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Informações Complementares" v-if="ficha.denominacao || ficha.incra || ficha.certificacaoIncra || ficha.itr || ficha.car || ficha.cadastroImobiliario || ficha.cadastroImobiliarioBrasileiro  || ficha.cadastroFiscal || ficha.andar || ficha.pavimento || ficha.grupo">
        <card-content>
          <p v-if="ficha.andar">
            <span class="font-weight-500">Andar: </span><span>{{ficha.andar}}</span>
          </p>
          <p v-if="ficha.pavimento">
            <span class="font-weight-500">Pavimento: </span><span>{{ficha.pavimento}}</span>
          </p>
          <p v-if="ficha.grupo">
            <span class="font-weight-500">Grupo (Tipo): </span><span>{{ficha.grupo}}</span>
          </p>
          <p v-if="ficha.denominacao">
            <span class="font-weight-500">Denominação da Área Rural: </span><span>{{ficha.denominacao}}</span>
          </p>
          <p v-if="ficha.incra">
            <span class="font-weight-500">INCRA (CCIR): </span><span>{{ficha.incra}}</span>
          </p>
          <p v-if="ficha.itr">
            <span class="font-weight-500">ITR: </span><span>{{ficha.itr}}</span>
          </p>
          <p v-if="ficha.car">
            <span class="font-weight-500">CAR: </span><span>{{ficha.car}}</span>
          </p>
          <p v-if="ficha.certificacaoIncra">
            <span class="font-weight-500">Certificação no SIGEF: </span><span>{{ficha.certificacaoIncra}}</span>
          </p>
          <p v-if="ficha.cadastroImobiliario">
            <span class="font-weight-500">Cadastro Imobiliário: </span><span>{{ficha.cadastroImobiliario}}</span>
          </p>
          <p v-if="ficha.cadastroImobiliarioBrasileiro">
            <span class="font-weight-500">Cadastro Imobiliário Brasileiro (CIB): </span><span>{{ficha.cadastroImobiliarioBrasileiro}}</span>
          </p>
          <p v-if="ficha.cadastroFiscal">
            <span class="font-weight-500">Cadastro Fiscal: </span><span>{{ficha.cadastroFiscal}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Endereço" v-if="ficha.cep || ficha.tipoLogradouro || ficha.logradouro || ficha.numero || ficha.bairro || ficha.cidade || ficha.estado || ficha.complemento">
        <card-content>

          <p v-if="ficha.cep">
            <span class="font-weight-500">CEP: </span><span>{{ficha.cep}}</span>
          </p>
          <p v-if="ficha.tipoLogradouro">
            <span class="font-weight-500">Tipo logradouro: </span><span>{{ficha.tipoLogradouro}}</span>
          </p>
          <p v-if="ficha.logradouro">
            <span class="font-weight-500">Logradouro: </span><span>{{ficha.logradouro}}</span>
          </p>
          <p v-if="ficha.numero">
            <span class="font-weight-500">Número: </span><span>{{ficha.numero}}</span>
          </p>
          <p v-if="ficha.bairro">
            <span class="font-weight-500">Bairro: </span><span>{{ficha.bairro}}</span>
          </p>
          <p v-if="ficha.cidade">
            <span class="font-weight-500">Cidade: </span><span>{{ficha.cidade}}</span>
          </p>
          <p v-if="ficha.cidadeIbge">
            <span class="font-weight-500">Cidade (IBGE): </span><span>{{ficha.cidadeIbge}}</span>
          </p>
          <p v-if="ficha.estado">
            <span class="font-weight-500">Estado: </span><span>{{ficha.estado}}</span>
          </p>
          <p v-if="ficha.complemento">
            <span class="font-weight-500">Complemento: </span><span>{{ficha.complemento}}</span>
          </p>

        </card-content>
      </card>

      <card titulo="Área" v-if="ficha.medidaArea || ficha.area || ficha.fracaoSolo || ficha.fracaoSoloPercentual || ficha.coeficienteProporcionalidade">
        <card-content>
          <p v-if="ficha.area">
            <span class="font-weight-500">Área total: </span><span>{{$formatters.currency(ficha.area,{min : 2, max : 10})}} {{unidadeArea}}</span>
          </p>

          <p v-if="ficha.fracaoSolo">
            <span class="font-weight-500">Fração solo: </span><span>{{$formatters.currency(ficha.fracaoSolo,{min : 2, max : 10})}} {{unidadeArea}}</span>
          </p>

          <p v-if="ficha.fracaoSoloPercentual">
            <span class="font-weight-500">Fração solo: </span><span>{{$formatters.currency(ficha.fracaoSoloPercentual,{min : 2, max : 10})}}%</span>
          </p>

          <p v-if="ficha.coeficienteProporcionalidade">
            <span class="font-weight-500">Coeficiente de proporcionalidade: </span><span>{{$formatters.currency(ficha.coeficienteProporcionalidade,{min : '0', max : 10})}}</span>
          </p>

          <p v-if="ficha.areaTotalDoi">
            <span class="font-weight-500">Área total DOI: </span><span>{{$formatters.currency(ficha.areaTotalDoi,{min : 2, max : 10})}} {{unidadeAreaTotalDoi}}</span>
          </p>
        </card-content>
      </card>

      <card titulo="Outras Áreas" v-if="ficha.areas && ficha.areas.length > 0">
        <table class="table table-striped">
          <thead>
          <tr>
            <th class="pl-2 w-25">TIPO</th>
            <th class="pl-2 w-50">DESCRIÇÃO</th>
            <th class="w-25">ÁREA</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(campo, index) in ficha.areas" :key="campo.descricao+'_'+index">
            <td class="pl-2 text-truncate">{{campo.tipo}}</td>
            <td class="pl-2 text-truncate">{{campo.descricao}}</td>
            <td class="text-truncate pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10})}}</td>
          </tr>
          </tbody>
        </table>
      </card>

      <card titulo="Áreas Edificadas" v-if="ficha.areasEdificadas && ficha.areasEdificadas.length > 0">
        <card-content>
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="pl-2 w-75">DESCRIÇÃO</th>
              <th class="w-25">ÁREA</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(campo, index) in ficha.areasEdificadas" :key="campo.descricao+'_'+index">
              <td class="pl-2">{{campo.descricao}}</td>
              <td class="text-truncate pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10})}}</td>
            </tr>
            </tbody>
          </table>
        </card-content>
      </card>

      <card titulo="Unidades Acessórias" v-if="ficha.unidadesAcessorias && ficha.unidadesAcessorias.length > 0">
        <card-content>
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="pl-2 w-75">DESCRIÇÃO</th>
              <th class="pl-2 w-75">UNIDADE</th>
              <th class="pl-2 w-75">COMPLEMENTO</th>
              <th class="w-25">ÁREA</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(campo, index) in ficha.unidadesAcessorias" :key="campo.descricao+'_'+index">
              <td class="pl-2">{{campo.descricao}}</td>
              <td class="pl-2 text-truncate">{{campo.unidade}}</td>
              <td class="pl-2">{{campo.complemento}}</td>
              <td class="text-truncate pr-2">{{$formatters.currency(campo.area,{min : 2, max : 10}, '-')}}</td>
            </tr>
            </tbody>
          </table>
        </card-content>
      </card>

      <card titulo="Confrontações" v-if="ficha.confrontacoes && ficha.confrontacoes.length > 0">
        <card-content>
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="pl-2">CONFRONTAÇÃO</th>
              <th class="pl-2">METRAGEM</th>
              <th class="pl-2">CONFRONTANTE</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(campo, index) in ficha.confrontacoes" :key="campo.confrontacao+'_'+index">
              <td class="pl-2">{{campo.confrontacao}}</td>
              <td class="pl-2 text-truncate">{{campo.metragem}}</td>
              <td class="pl-2">{{campo.confrontante}}</td>
            </tr>
            </tbody>
          </table>
        </card-content>
      </card>

      <card titulo="Especificações do Imóvel" v-if="ficha.especificacao">
        <card-content>
          <div v-html="ficha.especificacao"></div>
        </card-content>
      </card>

      <card titulo="Observações para Certidão" v-if="ficha.certidao">
        <card-content>
          <div v-html="ficha.certidao"></div>
        </card-content>
      </card>

      <card titulo="Observações Internas" v-if="ficha.observacao">
        <card-content>
          <div v-html="ficha.observacao"></div>
        </card-content>
      </card>

      <card titulo="Proprietários" v-if="!['ENCERRADA', 'CANCELADA'].includes(ficha.status) && ficha.proprietarios && ficha.proprietarios.length > 0">
        <template #acoes>
          <div class="small float-right" style="cursor: help" title="Total das frações dos proprietários">
            <span class="text-right text-green">{{$formatters.currency(totalProprietarios,{min : 2, max : 10})}}%</span>
          </div>
          <template v-if="exibirArea">
            <span class="small float-right mr-2 text-green">|</span>
            <div class="small float-right mr-2" style="cursor: help" :title="`Total das frações (${unidadeArea}) dos proprietários`">
              <span class="text-right text-green">{{$formatters.currency(totalProprietariosArea,{min : 2, max : 10})}}{{unidadeArea}}</span>
            </div>
          </template>
        </template>

        <table class="table table-striped">

          <thead>
            <tr>
              <th class="pl-2">PESSOA</th>
              <th>DOCUMENTO</th>
              <th class="text-right pr-2" v-if="exibirArea">{{unidadeArea}}</th>
              <th class="text-right pr-2">FRAÇÃO</th>
              <th></th>
            </tr>
          </thead>

          <tbody v-for="(proprietario, index) in ficha.proprietarios" :key="'ficha_proprietarios'+index">
          <tr>
            <td class="pl-2">
              <a href class="mb-1 pointer" @click.prevent="detalhes({indicadorPessoalId : proprietario.indicadorPessoalId}, proprietario.indicadorPessoalVersaoId)">
                {{proprietario.nome}} (Versão: {{proprietario.versao}})
              </a>
            </td>
            <td class="nowrap">{{proprietario.documento}}</td>
            <td class="nowrap text-right pr-2" width="120px" v-if="exibirArea">{{$formatters.currency(proprietario.area,{min : 2, max : 10})}}</td>
            <td class="nowrap text-right pr-2" width="120px">{{$formatters.currency(proprietario.fracao,{min : 2, max : 10})}}%</td>
            <td class="td-toggle">
              <toggle :mostrar="chave(proprietario) == mostrarVersoesProprietario" :run="change" :params="[proprietario]" :chave="chave(proprietario)" class="ml-2 icon" />
            </td>
          </tr>
          <tr v-if="chave(proprietario) == mostrarVersoesProprietario">
            <td colspan="4">
              <card titulo="Atos proprietário">
                <div class="alert alert-outline-secondary" style="margin-bottom: 5px; font-size: 13px;" v-for="versaoAto in proprietario.atosPorVersao" :key="versaoAto.codigo">
                  <a style="margin-left: 5px;" href class="mb-1 pointer" @click.prevent="detalhes(versaoAto, versaoAto.versaoId)">
                    {{versaoAto.nome}} - {{versaoAto.documento}} (Versão: {{versaoAto.versao}})</a>
                  <span style="float: right;margin-right: 5px;">{{getCodigo(versaoAto.ato)}} - {{versaoAto.ato.nome}}</span>
                </div>
                <div v-if="proprietario.atosPorVersao.length == 0" class="alert alert-outline-info">
                  Não há ato registrado para proprietário
                </div>
              </card>
            </td>
          </tr>
          </tbody>

        </table>

      </card>
      <card titulo="Registros Auxiliares Relacionados" v-if="ficha.registroAuxiliar && ficha.registroAuxiliar.length">
        <card-table>
          <thead>
            <tr>
              <th>Registro Auxiliar</th>
              <th>Texto</th>
              <th>Observações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ficha in ficha.registroAuxiliar" :key="ficha.id">
              <td class="pointer icon" @click="detalhesFicha(ficha.id)">
                <a>{{nomearFicha(ficha)}}</a>
              </td>
              <td style="width: 370px">
                <toggle v-if="ficha.texto && ficha.texto.length > 80" :mostrar="chaveRA(ficha) === mostrarCompletoRA" :run="exibirCompletoRA" :params="[ficha]" :chave="chaveRA(ficha)" class="ml-2 icon float-right" />

                <div :class="{'ellipsis-minuta': chaveRA(ficha) !== mostrarCompletoRA}" class="w-100 pointer"
                     v-html="ficha.texto">
                </div>
              </td>
              <td style="width: 370px">
                <toggle v-if="ficha.observacoes && ficha.observacoes.length > 80" :mostrar="chaveRA(ficha) === mostrarCompletoRA" :run="exibirCompletoRA" :params="[ficha]" :chave="chaveRA(ficha)" class="ml-2 icon float-right" />

                <div :class="{'ellipsis-minuta': chaveRA(ficha) !== mostrarCompletoRA}" class="w-100 pointer"
                     v-html="ficha.observacoes">
                </div>
              </td>
            </tr>
          </tbody>

        </card-table>
      </card>

      <TabelaRestricoesAtivas :ficha="ficha" indicadorReal/>
    </template>

  </div>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
  import AtoBusiness from "@/business/protocolo/AtoBusiness";
  import DominioBusiness from "@/business/protocolo/DominioBusiness.js";
  import DetalhesIndicadorPessoal from "@/components/IndicadorPessoal/Detalhes/DetalhesIndicadorPessoal";
  import ReferenciaImovelLista from "@/components/IndicadorReal/ReferenciaImovelLista.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import CardTable from "@/components/Layout/components/CardTable.vue";
  import Toggle from "@/components/Layout/components/Toggle";
  import DetalhesRegistroAuxiliar from "@/components/RegistroAuxiliar/Detalhes/DetalhesRegistroAuxiliar.vue";
  import TabelaRestricoesAtivas from "@/components/Restricao/TabelaRestricoesAtivas";
  import DetalhesIndicadorReal from "@/components/IndicadorReal/Detalhes/DetalhesIndicadorReal";
  import SmallLoading from "@/components/SmallLoading";
  import DetalhesIndicadorRealExterno
    from "@/components/IndicadorRealExterno/Detalhes/DetalhesIndicadorRealExterno.vue";

  export default {

    name: "Dados",
    components: {ReferenciaImovelLista, TabelaRestricoesAtivas, CardTable, CardContent, Card, Toggle, SmallLoading},
    props: {
      id: String,
      preview: Object
    },

    data() {
      return {
        mostrarVersoesProprietario : null,
        mostrarCompletoRA : null,
        ficha: {},
        loading : true,
        status: IndicadorRealBusiness.getStatusImovel(),
        regimes: IndicadorRealBusiness.getRegimesImovelUniao()
      }
    },
    async mounted(){
      await this.load();

      if(this.ficha.estado === null || this.ficha.cidade === null){
        // this.ficha.estado = this.$root.config?.estado;
        // this.ficha.cidade = this.$root.config?.cidade;
      } else {
        this.ficha.estado = this.ficha.estado;
        this.ficha.cidade = this.ficha.cidade;
      }
    },

    computed: {

      mostrarCabecalhoOrigens(){
        return this.ficha.origens.filter(e => e.tipo != 'LEGADO' && e.tipo != 'TEXTO').length > 0;
      },

      exibirEndereco() {
        return ['cep', 'logradouro', 'numero', 'bairro', 'cidade', 'estado', 'complemento', 'quadra', 'lote'].some(e => this.ficha[e]);
      },

      tituloCnm(){
        return this.ficha.cnm !== null ? 'Regerar CNM' : 'Gerar CNM';
      },

      exibirArea() {
        return this.ficha.area && this.ficha.medidaArea;
      },

      livroTranscricao(){
        return FrontBusiness.getLabel(this.ficha.livroTranscricao, IndicadorRealBusiness.getLivrosTranscricao());
      },

      labelTipoImovel() {
        return IndicadorRealBusiness.getTiposImovel().find(e => e.id == this.ficha?.tipoImovel)?.nome;
      },

      labelCategoria() {
        return IndicadorRealBusiness.getCategorias().find(e => e.id == this.ficha?.categoria)?.nome +
          [this.ficha?.unidadeAutonoma ? " (Unidade Autônoma)" : ""];
      },

      origens(){
        return this.ficha.origens.map(o => {
          o.default = o.tipo;
          o.serventia = o.serventia === 'null' ? null : o.serventia;
          if(o.tipo == "MATRICULA"){
            o.tipo = "Matrícula";
            o.livroNumero = '';
            o.livroFolha = '';
            o.livroLetra = o.livroLetra || '';
          }

          if(o.tipo == "PRE_MATRICULA"){
            o.tipo = "Pré-Matrícula";
            o.livroNumero = '';
            o.livroFolha = '';
            o.livroLetra = o.livroLetra || '';
          }

          if(o.tipo == "TRANSCRICAO"){
            o.tipo = `Transcrição`;
          }

          if(o.tipo == "INSCRICAO"){
            o.tipo = `Inscrição`;
          }

          if(o.tipo == "TORRENS"){
            o.tipo = `Torrens`;
          }

          if(o.tipo == 'MATRICULA_EXTERNA'){
            let sufixo = { 'MATRICULA': 'M', 'TRANSCRICAO': 'TR', 'TORRENS': 'TO' };
            o.tipo = `Indicador Real Externo - ${sufixo[o.tipoIndicadorRealExterno]}`;
          }

          o.consulta = (['TRANSCRICAO'].includes(o.default) && o.origemId && o.interno) || (['MATRICULA', 'PRE_MATRICULA'].includes(o.default) && o.origemId) || (['MATRICULA_EXTERNA'].includes(o.default) && o.origemExternaId);
          return o;
        });
      },

      unidadeArea(){
        return this.ficha.medidaArea ? FrontBusiness.getLabel(this.ficha.medidaArea, IndicadorRealBusiness.getMedidas()) : '';
      },

      unidadeAreaTotalDoi(){
        return this.ficha.medidaAreaDoi ? FrontBusiness.getLabel(this.ficha.medidaAreaDoi, IndicadorRealBusiness.getMedidas()) : '';
      },


      unidadeAreaUniao(){
        return this.ficha.medidaAreaUniao ? FrontBusiness.getLabel(this.ficha.medidaAreaUniao, IndicadorRealBusiness.getMedidasUniao()) : '';
      },

      totalProprietarios() {
        return this.ficha.proprietarios.reduce((total, e) => total + e.fracao, 0);
      },

      totalProprietariosArea() {
        return this.ficha.proprietarios.reduce((total, e) => total + e.area, 0);
      },



    },

    methods: {
      async load(){
        this.loading = true;
        if (!!this.preview) {
          this.ficha = this.preview;
        } else {
          let ficha = await IndicadorRealBusiness.getById(this.id);
          ficha.proprietarios = await IndicadorRealBusiness.proprietariosAtuais(ficha.id);
          ficha.registroAuxiliar = await IndicadorRealBusiness.getVinculos(ficha.id);
          this.ficha = ficha;
        }

        this.loading = false;
      },

      formatarCodigo : FrontBusiness.formatarCodigo,
      detalhes(pessoa, versao) {
        FrontBusiness.openModal(DetalhesIndicadorPessoal, {id: pessoa.indicadorPessoalId, versao});
      },

      async change(proprietario){
        let chaveProprietario = this.chave(proprietario);
        if(chaveProprietario == this.mostrarVersoesProprietario){
          this.mostrarVersoesProprietario = null;
        } else {
          await this.buscarAtosPorVersaoIndicadorPessoal(this.ficha.id, proprietario);
          this.mostrarVersoesProprietario = chaveProprietario;
        }
      },

      async exibirCompletoRA(proprietario){
        let chave = this.chaveRA(proprietario);
        if(chave === this.mostrarCompletoRA){
          this.mostrarCompletoRA = null;
        } else {
          this.mostrarCompletoRA = chave;
        }
      },

      chave(proprietario) {
        return `pessoa-id-${proprietario.id}`
      },

      chaveRA(ficha) {
        return `ra-id-${ficha.id}`
      },

      async buscarAtosPorVersaoIndicadorPessoal(fichaId, proprietario){
        if(!proprietario.atosPorVersao){
          proprietario.atosPorVersao = await IndicadorRealBusiness.buscarAtosPorVersaoIndicadorPessoal(fichaId, proprietario.indicadorPessoalVersao?.indicadorPessoalId || proprietario?.indicadorPessoalId);
          proprietario.atosPorVersao.forEach(e => e.ato = JSON.parse(e.ato));
        }
      },

      getCodigo(ato) {
        return AtoBusiness.getCodigo(ato, this.ficha);
      },

      async detalhesIndicadorReal(origem){
        if(['TRANSCRICAO'].includes(origem.default) && origem.origemId && origem.interno){
          this.detalhesFichaIndicadorReal(origem.origemId);
        }
        if(['MATRICULA', 'PRE_MATRICULA'].includes(origem.default) && origem.origemId){
          this.detalhesFichaIndicadorReal(origem.origemId);
        }
        if(['MATRICULA_EXTERNA'].includes(origem.default) && origem.origemExternaId){
          await FrontBusiness.openModal(DetalhesIndicadorRealExterno, {id: origem.origemExternaId});
        }
      },

      formatarRegistro(i){
        return i?.replace(/[^a-zA-Z0-9 ]/g, "");
      },

      async detalhesFichaIndicadorReal(id){
        await FrontBusiness.openModal(DetalhesIndicadorReal, {id});
      },

      nomearFicha(ficha){
        return FrontBusiness.nomearFicha(ficha);
      },

      detalhesFicha(id) {
        FrontBusiness.openModal(DetalhesRegistroAuxiliar, {id});
      },

      async gerarCNM(id) {
        try {
          if (this.ficha.cnm !== null) {
            const r = await FrontBusiness.showConfirmacao(
              'Você está prestes a regerar o CNM desse indicador.',
              'Deseja continuar?',
              'checkbox',
              'Tenho certeza que desejo continuar.'
            );

            if (!r.value) {
              return; // Cancela se o usuário não confirmar
            }
          }

          const response = await IndicadorRealBusiness.gerarCNM(id);
          this.ficha.cnm = Object.freeze(response.cnm);
          FrontBusiness.showSuccess('', response.message);

        } catch (error) {
          FrontBusiness.showError('', error.message);
        }
      }

    }
  }
</script>

<style scoped>

  .td-toggle {
    text-align:right;
    vertical-align:top;
  }
  tbody {
    border-top-width: 0px !important;
  }

  .ellipsis-minuta {
    display: -webkit-box;
    max-height: 95px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

</style>
