<template>

  <modal titulo="Capturar biometria do indicador pessoal">

    <card>
      <div>
        <div class="selection-section">
          <v-input label="Escolha um dedo para impressão digital:" v-model="selectedFinger" :options="allFingers" type="select"/>
        </div>

        <div class="preview">
          <h3>Pré-visualização da Digital</h3>
          <div class="finger-preview">
            <div v-if="isLoading" class="loading-message">
              Capturando digital... Insira o dedo somente quando a luz do aparelho acender.
            </div>
            <img v-else-if="imageSrc" :src="imageSrc" alt="Fingerprint Image" class="finger-image" />
          </div>
        </div>
      </div>
    </card>

    <template #footer>
      <button type="button" @click="close" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button v-if="!fingerprintCaptured" :run="captureFingerprint" class="btn-outline-primary">Capturar Biometria</v-button>
      <template v-else>
        <v-button :run="captureFingerprint" :popover="true" class="btn-outline-secondary mr-2">Capturar novamente</v-button>
        <v-button :run="saveFingerprint" :popover="true" class="btn-outline-primary">Salvar</v-button>
      </template>
    </template>

  </modal>

</template>

<script>
import AnexoBusiness from "@/business/AnexoBusiness";
import BiometriaBusiness from "@/business/biometria/BiometriaBusiness";
import Card from "@/components/Layout/components/Card.vue";
import FrontBusiness from "@/business/FrontBusiness";
import Modal from "@/components/Modal.vue";

export default {
  name: "Biometria",
  components: {Modal, Card },
  props: {
    id: String,
  },

  data() {
    return {
      isLoading: false,
      fingerprintCaptured: false,
      selectedFinger: null,
      imageSrc: null,
      allFingers: [
        {id: 1, nome: "Polegar Direito"},
        {id: 2, nome: "Polegar Esquerdo"},
        {id: 3, nome: "Indicador Direito"},
        {id: 4, nome: "Indicador Esquerdo"},
        {id: 5, nome: "Médio Direito"},
        {id: 6, nome: "Médio Esquerdo"},
        {id: 7, nome: "Anelar Direito"},
        {id: 8, nome: "Anelar Esquerdo"},
        {id: 9, nome: "Mindinho Esquerdo"},
        {id: 10, nome: "Mindinho Direito"}
      ],
    };
  },

  mounted() {
    this.selectedFinger = this.allFingers[0].id;
  },
  methods: {
    async captureFingerprint() {
      if (!this.selectedFinger) {
        FrontBusiness.showError("Selecione um dedo antes de capturar a digital.");
        return;
      }

      this.isLoading = true;

      try {
        const response = await BiometriaBusiness.getFingerprint();
        const base64String = await this.arrayBufferToBase64Jpeg(response);
        this.imageSrc = `data:image/jpeg;base64,${base64String}`;
        this.fingerprintCaptured = true;
        FrontBusiness.showSuccess('', 'Biometria coletada com sucesso')
      } catch (e) {
        FrontBusiness.showError("",`Não foi possível realizar coleta de biometria. Tente novamente.`);
        this.fingerprintCaptured = false;
        console.error("Erro ao capturar a digital:", e);
      }finally {
        this.isLoading = false;
      }
    },
    async arrayBufferToBase64Jpeg(buffer) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([buffer], { type: "image/jpeg" });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
      });
    },
    base64ToFile(base64, mimeType, fileName) {
      const binaryString = atob(base64);
      const length = binaryString.length;
      const uint8Array = new Uint8Array(length);

      for (let i = 0; i < length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      return new File([uint8Array], fileName, { type: mimeType });
    },
    close() {
      this.fingerprintCaptured = false;
      this.selectedFinger = null;
      this.imageSrc = null;
      FrontBusiness.closeModal();
    },
    async saveFingerprint() {
      const mimeType = "image/jpeg"
      const fileName = this.allFingers[this.selectedFinger - 1].nome + ".jpeg";
      const file = this.base64ToFile(this.imageSrc.split(",")[1], mimeType, fileName);
      await AnexoBusiness.inserir(file, 'INDICADOR_PESSOAL_CAPTURA_BIOMETRIA', this.id, null, false, null, null)
        .then((response) => {
          FrontBusiness.showSuccess('', 'Biometria salva com sucesso')
          this.close();
        })
        .catch((error) => {
          FrontBusiness.showError('', "Erro ao salvar a biometria");
          console.error("Erro ao salvar a biometria:", error);
        });
    }
  }
};
</script>

<style scoped>
.preview {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}
.finger-preview {
  margin: 10px 0;
}
.finger-image {
  max-width: 100px;
  max-height: 100px;
  border: 1px solid #000;
  border-radius: 5px;
}
.loading-message {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  text-align: center;
  padding: 10px;
}
</style>
