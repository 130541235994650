import Http from "@/commons/Http";

export default {

  async getFingerprint() {
    return Http.get("http://localhost:8080/fingerprint", { withCredentials: false, responseType: 'blob' });
  }


}
