<template>
  <modal titulo="Odin - Processamento de Ato" id="odin-processamento-ato"
         :fechar="true" @close="close" :loading="loading">

    <card titulo="Minuta do ato" v-if="!loading">
      <card-content>
        <div class="texto-minuta mb-2" v-html="dataBus.ato.texto" v-if="dataBus.ato.texto"></div>
        <v-button class="btn btn-outline-primary" :run="run" :popover="true">
          Processar Texto
        </v-button>
      </card-content>
    </card>

    <card titulo="Situação Jurídica Anterior" v-if="!loading && dataBus.ia && dataBus.ia.situacaoJuridicaAnterior">
      <card-content>
        <div class="texto-minuta mb-2" v-html="dataBus.ia.situacaoJuridicaAnterior.replaceAll('\n', '<br/>')"></div>
      </card-content>
    </card>

    <card titulo="Situação Jurídica Atualizada" v-if="!loading && dataBus.ia && dataBus.ia.situacaoJuridica">
      <card-content>
        <div class="texto-minuta mb-2" v-html="dataBus.ia.situacaoJuridica.replaceAll('\n', '<br/>')"></div>
      </card-content>
    </card>

    <card class="border-transparent" v-if="!loading">

      <template #acoes>
        <ul class="nav nav-tabs">
          <li class="nav-item" v-for="menu in menus">
            <a class="nav-link" :class="{ active: activeTab === menu.id }"
               href="#" @click.prevent="activeTab = menu.id">
              {{menu.nome}}
            </a>
          </li>
        </ul>
      </template>

      <card-content id="conteudo-processamento">

        <card-table class="table-borderless table-striped mt-2" v-if="activeTab == 'geral'">
          <thead>
            <tr style="background: transparent" class="small">
              <th style="width:200px">Campo</th>
              <th style="width:400px">Atual</th>
              <th style="width:400px">Extraído</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dadosGerais" :key="index">
              <td>{{ item.campo }}</td>
              <td>{{ item.atual }}</td>
              <td>{{ item.extraido }}</td>
            </tr>
          </tbody>
        </card-table>

        <card-table class="table-borderless table-striped mt-2" v-if="activeTab == 'indicadorreal'">
          <thead>
            <tr style="background: transparent" class="small">
              <th style="width:200px">Campo</th>
              <th style="width:400px">Atual</th>
              <th style="width:400px">Extraído</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dadosIndicadorReal" :key="index">
              <td>{{ item.campo }}</td>
              <td>{{ item.atual }}</td>
              <td>{{ item.extraido }}</td>
            </tr>
          </tbody>
        </card-table>

        <card-table class="table-borderless table-striped mt-2" v-if="activeTab == 'envolvidos'">
          <thead>
            <tr style="background: transparent" class="small">
              <th colspan=3 style="width:400px">Atual</th>
              <th style="width: 50px"></th>
              <th colspan=3 style="width:400px">Extraído</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in envolvidos">
              <tr class="small">
                <td>
                  {{item.atual ? item.atual.nome || '-' : '-'}}
                  <span class="text-muted d-block" v-if="item.atual && item.atual.documento">{{item.atual.documento}}</span>
                </td>
                <td>{{item.atual ? item.atual.papel || '-' : '-'}}</td>
                <td>{{item.atual ? item.atual.fracao || '-' : '-'}}</td>
                <td style="background-color: #1C1E24;"></td>
                <td>
                  {{item.extraido ? item.extraido.nome || '-' : '-'}}
                  <span class="text-muted d-block" v-if="item.extraido && item.extraido.documento">{{item.extraido.documento}}</span>
                </td>
                <td>{{item.extraido && item.extraido.papel ? item.extraido.papel.join(', ') || '-' : '-'}}</td>
                <td>{{item.extraido ? item.extraido.percentual || '-' : '-'}}</td>
              </tr>
            </template>
          </tbody>
        </card-table>

      </card-content>

    </card>

  </modal>
</template>

<script>

import AtoBusiness from "@/business/protocolo/AtoBusiness";
import Utils from "@/commons/Utils";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Modal from "@/components/Modal.vue";
import FrontBusiness from "@/business/FrontBusiness";
import OdinAtosMatricula from "@/views/Odin/componentes/OdinAtosMatricula.vue";
import OdinEnvolvidosAto from "@/views/Odin/componentes/OdinEnvolvidosAto.vue";
import OdinBusiness from "@/views/Odin/OdinBusiness";

export default {
  name: 'ModalOdinProcessamentoAto',
  components: {
    CardTable,
    OdinAtosMatricula, OdinEnvolvidosAto, Card, CardContent, Modal},
  props:{
    indicadorRealId: String,
    atoId: String,
  },

  modal: {
    width: 1200
  },

  async mounted() {
    this.load();
  },

  data(){
    return {
      activeTab: "geral",
      loading: true,
      dadosGerais:[],
      dadosIndicadorReal:[],
      dataBus: {
        ato: null,
        ia: null
      }
    }
  },

  computed:{
    menus(){
      const menus = [{id: 'geral', nome: 'Dados Gerais'}];
      if(this.dadosIndicadorReal.length > 0){
        menus.push({id: 'indicadorreal', nome: 'Indicador Real'});
      }
      if(this.dataBus.ia?.envolvidos?.length > 0){
        menus.push({id: 'envolvidos', nome: 'Envolvidos do Ato'});
      }
      return menus;
    },
    envolvidos(){

      let envolvidos = this.dataBus.ato.envolvidos.map(r => ({
        nome: r.nome,
        atual: {
          id: r.id,
          indicadorPessoalId: r.indicadorPessoalId,
          nome: r.nome,
          fracao: r.fracao,
          papel: r.papel,
          documento: r.indicadorPessoalVersao?.indicadorPessoal?.documento
        }}));

      const extraidos = this.dataBus.ia?.envolvidos || [];

      envolvidos.forEach(a => {

        for(let pos in extraidos) {

          //possui documento e sao iguais
          if(Utils.extrairNumeros(a.atual.documento) && Utils.extrairNumeros(a.atual.documento) == Utils.extrairNumeros(extraidos[pos].documento)
            && OdinBusiness.calcularSimilaridade(a.atual.nome, extraidos[pos].nome) >= 70){
            a.extraido = extraidos[pos];
            delete extraidos[pos];
            break;
          }

          // if(!Utils.extrairNumeros(a.atual.documento) && OdinBusiness.calcularSimilaridade(a.atual.nome, extraidos[pos].nome) >= 80){
          //   a.extraido = extraidos[pos];
          //   delete extraidos[pos];
          //   break;
          // }

        }

      })

      //após processar a lista, processa novamente com os que ficaram pra trás
      envolvidos.forEach(a => {
        if(!a.extraido){
          for (let pos in extraidos) {
            if(OdinBusiness.calcularSimilaridade(a.atual.nome, extraidos[pos].nome) >= 80){
              a.extraido = extraidos[pos];
              delete extraidos[pos];
              break;
            }
          }
        }
      });

      for(let pos in extraidos) {
        envolvidos.push({
          nome: extraidos[pos].nome,
          atual: null,
          extraido: extraidos[pos]
        })
      }

      envolvidos = Utils.orderBy(envolvidos, 'nome')

      return envolvidos;

    }
  },

  methods:{
    async close(retorno) {
      FrontBusiness.closeModal(retorno);
    },

    async load(){
      this.$set(this, 'loading', true);
      const ato = await AtoBusiness.getById(null, this.atoId);
      const ia = await OdinBusiness.capturarExtracaoAto(this.atoId).catch(r => {
        return null;
      });

      const dadosGerais = [];
      // if(ia?.atoMatricula?.numeroAto) dadosGerais.push({id: 'numeroAto', campo: 'numeroAto', extraido: ia.atoMatricula.numeroAto, atual: ato?.numeroAto})
      // if(ia?.atoMatricula?.tipoAto) dadosGerais.push({id: 'tipoAto', campo: 'tipoAto', extraido: ia.atoMatricula.tipoAto, atual: ato?.tipoAto})
      if(ia?.atoMatricula?.dataAto) dadosGerais.push({id: 'dataAto', campo: 'Data do Ato', extraido: this.$formatters.date(ia.atoMatricula.dataAto), atual: this.$formatters.date(ato?.registro)})
      // if(ia?.atoMatricula?.tituloAto) dadosGerais.push({id: 'tituloAto', campo: 'Título', extraido: ia.atoMatricula.tituloAto, atual: ato?.titulo})
      if(ia?.atoMatricula?.negocios) dadosGerais.push({id: 'negocios', campo: 'Negócio Jurídico', extraido: ia.atoMatricula.negocios?.join(', '), atual: null})
      if(ia?.atoMatricula?.dataTransacao) dadosGerais.push({id: 'dataTransacao', campo: 'Data da Transação', extraido: this.$formatters.date(ia.atoMatricula.dataTransacao), atual: null})
      if(ia?.atoMatricula?.valorTransacao) dadosGerais.push({id: 'valorTransacao', campo: 'Valor da Transação', extraido: this.$formatters.currency(ia.atoMatricula.valorTransacao), atual: null})
      if(ia?.atoMatricula?.moedaTransacao) dadosGerais.push({id: 'moedaTransacao', campo: 'Moeda  da Transação', extraido: ia.atoMatricula.moedaTransacao, atual: null})
      if(ia?.atoMatricula?.criacaoRestricoes) dadosGerais.push({id: 'criacaoRestricoes', campo: 'Registro de Restrição', extraido: ia.atoMatricula.criacaoRestricoes, atual: null})
      if(ia?.atoMatricula?.cancelamentoRestricoes) dadosGerais.push({id: 'cancelamentoRestricoes', campo: 'Cancelamento de Restrição', extraido: ia.atoMatricula.cancelamentoRestricoes, atual: null})
      if(ia?.atoMatricula?.cancelamentoAto) dadosGerais.push({id: 'cancelamentoAto', campo: 'Cancelamento de Ato', extraido: ia.atoMatricula.cancelamentoAto, atual: null})

      const dadosIndicadorReal = [];
      if(ia?.indicadorReal?.tipoImovel) dadosIndicadorReal.push({id: 'tipoImovel', campo: 'tipoImovel', extraido: ia.indicadorReal.tipoImovel, atual: ato.ficha?.tipoImovel})
      if(ia?.indicadorReal?.numeroUnidade) dadosIndicadorReal.push({id: 'numeroUnidade', campo: 'numeroUnidade', extraido: ia.indicadorReal.numeroUnidade, atual: ato.ficha?.numeroUnidade})
      if(ia?.indicadorReal?.tipoLogradouro) dadosIndicadorReal.push({id: 'tipoLogradouro', campo: 'tipoLogradouro', extraido: ia.indicadorReal.tipoLogradouro, atual: ato.ficha?.tipoLogradouro})
      if(ia?.indicadorReal?.nomeRua) dadosIndicadorReal.push({id: 'nomeRua', campo: 'nomeRua', extraido: ia.indicadorReal.nomeRua, atual: ato.ficha?.nomeRua})
      if(ia?.indicadorReal?.numeroImovel) dadosIndicadorReal.push({id: 'numeroImovel', campo: 'numeroImovel', extraido: ia.indicadorReal.numeroImovel, atual: ato.ficha?.numeroImovel})
      if(ia?.indicadorReal?.complemento) dadosIndicadorReal.push({id: 'complemento', campo: 'complemento', extraido: ia.indicadorReal.complemento, atual: ato.ficha?.complemento})
      if(ia?.indicadorReal?.bairro) dadosIndicadorReal.push({id: 'bairro', campo: 'bairro', extraido: ia.indicadorReal.bairro, atual: ato.ficha?.bairro})
      if(ia?.indicadorReal?.cidade) dadosIndicadorReal.push({id: 'cidade', campo: 'cidade', extraido: ia.indicadorReal.cidade, atual: ato.ficha?.cidade})
      if(ia?.indicadorReal?.estado) dadosIndicadorReal.push({id: 'estado', campo: 'estado', extraido: ia.indicadorReal.estado, atual: ato.ficha?.estado})
      if(ia?.indicadorReal?.condominio) dadosIndicadorReal.push({id: 'condominio', campo: 'condominio', extraido: ia.indicadorReal.condominio, atual: ato.ficha?.condominio})
      if(ia?.indicadorReal?.bloco) dadosIndicadorReal.push({id: 'bloco', campo: 'bloco', extraido: ia.indicadorReal.bloco, atual: ato.ficha?.bloco})
      if(ia?.indicadorReal?.loteamento) dadosIndicadorReal.push({id: 'loteamento', campo: 'loteamento', extraido: ia.indicadorReal.loteamento, atual: ato.ficha?.loteamento})
      if(ia?.indicadorReal?.lote) dadosIndicadorReal.push({id: 'lote', campo: 'lote', extraido: ia.indicadorReal.lote, atual: ato.ficha?.lote})
      if(ia?.indicadorReal?.quadra) dadosIndicadorReal.push({id: 'quadra', campo: 'quadra', extraido: ia.indicadorReal.quadra, atual: ato.ficha?.quadra})
      if(ia?.indicadorReal?.areaPrivativa) dadosIndicadorReal.push({id: 'areaPrivativa', campo: 'areaPrivativa', extraido: ia.indicadorReal.areaPrivativa, atual: ato.ficha?.areaPrivativa})
      if(ia?.indicadorReal?.areaComum) dadosIndicadorReal.push({id: 'areaComum', campo: 'areaComum', extraido: ia.indicadorReal.areaComum, atual: ato.ficha?.areaComum})
      if(ia?.indicadorReal?.areaConstruida) dadosIndicadorReal.push({id: 'areaConstruida', campo: 'areaConstruida', extraido: ia.indicadorReal.areaConstruida, atual: ato.ficha?.areaConstruida})
      if(ia?.indicadorReal?.areaTotal) dadosIndicadorReal.push({id: 'areaTotal', campo: 'areaTotal', extraido: ia.indicadorReal.areaTotal, atual: ato.ficha?.areaTotal})
      if(ia?.indicadorReal?.fracaoIdeal) dadosIndicadorReal.push({id: 'fracaoIdeal', campo: 'fracaoIdeal', extraido: ia.indicadorReal.fracaoIdeal, atual: ato.ficha?.fracaoIdeal})
      if(ia?.indicadorReal?.denominacao) dadosIndicadorReal.push({id: 'denominacao', campo: 'denominacao', extraido: ia.indicadorReal.denominacao, atual: ato.ficha?.denominacao})
      if(ia?.indicadorReal?.confrontantes) dadosIndicadorReal.push({id: 'confrontantes', campo: 'confrontantes', extraido: ia.indicadorReal.confrontantes, atual: ato.ficha?.confrontantes})

      this.$set(this.dataBus, 'ato', ato);
      this.$set(this.dataBus, 'ia', ia);
      this.$set(this, 'dadosIndicadorReal', dadosIndicadorReal);
      this.$set(this, 'dadosGerais', dadosGerais);

      console.debug(this.dataBus.ia)
      this.$set(this, 'loading', false);
    },

    async run(){
      this.$set(this, 'loading', true);
      await OdinBusiness.realizarExtracaoAto(this.atoId, true).catch(r => {
        FrontBusiness.showError('', r.message);
      });
      await this.load();
    },

    async salvar(){
    },

    showError(e) {
      FrontBusiness.showError('', e.message);
    },
  }
}
</script>
